.item-page {
	margin-top: 20px;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    	-webkit-font-smoothing: antialiased;
    	-moz-osx-font-smoothing: grayscale;
    }
    
    .row {
	display: flex;
	flex-wrap: wrap;
    }
    
    .item-image {
	flex: 0 0 100%;
	max-width: 100%;
	margin-right: 0;
	padding: 0 15px;
    }
    
    @media (min-width: 768px) {
	.item-image {
	    flex: 0 0 40%;
	    max-width: 40%;
	}
    }
    
    .carousel-root {
	width: 100%;
	max-width: 400px;
	margin: 0 auto;
    }
    
    .carousel .slide img {
	width: 100%;
	height: auto;
	object-fit: contain;
	border-radius: 15px;
	max-height: 400px;
    }
    
    .carousel .thumbs-wrapper {
	margin: 20px 0 0 0 !important;
    }
    
    .carousel .thumb {
	width: 80px;
	height: 80px;
	display: inline-block;
	margin: 5px;
	border-radius: 10px;
    }
    
    .carousel .thumb img {
	width: 100%;
	height: 100%;
	object-fit: cover;
	border-radius: 10px;
    }
    
    .carousel .thumb:hover,
    .carousel .thumb.selected {
	border: 2px solid #007bff;
    }
    
    .carousel .control-arrow,
    .carousel .carousel-status {
	display: none;
    }
    
    .item-details {
	flex: 1;
	margin-left: 0;
	margin-top: 20px;
	text-align: left;
	padding: 0 15px;
    }
    
    @media (min-width: 768px) {
	.item-details {
	    flex: 0 0 30%;
	    max-width: 30%;
	    margin-top: 0;
	}
    }
    
    .item-title {
	font-size: 24px;
	margin-bottom: 10px;
    }
    
    .item-rating {
	color: #ffa41c;
	margin-bottom: 10px;
    }
    
    .item-price {
	font-size: 18px;
	font-weight: bold;
	margin-bottom: 10px;
    }
    
    .item-availability {
	color: #007600;
	margin-bottom: 10px;
    }
    
    .item-description {
	margin-bottom: 20px;
    }
    
    .add-to-cart-btn {
	background-color: #ffd814;
	border: none;
	padding: 10px 20px;
	font-size: 16px;
	cursor: pointer;
    }
    
    .add-to-cart-btn:hover {
	background-color: #f7ca00;
    }
    
    .specifics-section {
	margin-top: 20px;
	border-top: 1px solid #e0e0e0;
	padding-top: 20px;
    }
    
    .specifics-section h2 {
	font-size: 1.2em;
	margin-bottom: 15px;
    }
    
    .specific-item {
	margin-bottom: 20px;
    }
    
    .specific-item strong {
	display: block;
	margin-bottom: 10px;
	font-size: 1.1em;
    }
    
    .option-group {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
    }
    
    .option-tile {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f0f0f0;
	border-radius: 12px;
	cursor: pointer;
	transition: all 0.3s ease;
	border: 2px solid transparent;
	overflow: hidden;
	position: relative;
	padding: 1px 3px;
    }
    
    .option-tile:hover {
	transform: scale(1.05);
    }
    
    .option-tile.selected {
	border-color: #007bff;
	box-shadow: 0 0 10px rgba(0, 123, 255, 0.5);
    }
    
    .option-text {
	font-size: 0.8em;
	text-align: center;
	padding: 5px;
	word-break: break-word;
    }
    
    .color-tile {
	border: 1px solid #676767;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #f0f0f0;
	border-radius: 12px;
	cursor: pointer;
	transition: all 0.3s ease;
	overflow: hidden;
	position: relative;
	width: 60px;
	height: 60px;
    }
    
    .color-tile.selected::after {
	content: '✓';
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	color: white;
	font-size: 1.5em;
	text-shadow: 0 0 3px rgba(0,0,0,0.5);
    }
    
    .option-description {
	margin-top: 0.5rem;
	font-style: italic;
	color: #666;
    }
    
    .shipping-info {
	background-color: #f8f9fa;
	border-radius: 8px;
	border: 1px solid #e0e0e0;
	margin-top: 20px;
	display: flex;
	flex-direction: column;
    }
    
    .shipping-info-content {
	flex-grow: 1;
	overflow-y: auto;
	padding: 15px;
    }

    @media (min-width: 768px) {
	.col-md-3 {
	    flex: 0 0 25%;
	    max-width: 25%;
	}
	
	.shipping-info {
		margin-top: 0;
		position: sticky;
		top: 20px; /* Adjust this value as needed */
	}
    }
    
    .shipping-info h3,
    .shipping-info h4 {
	font-size: 1.1em;
	margin-bottom: 10px;
    }
    
    .ship-to {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 10px;
    }
    
    .ship-to h3 {
	margin: 0;
    }
    
    .aliexpress-commitment {
	background-color: #fffbe6;
	border: 1px solid #ffe58f;
	border-radius: 4px;
	padding: 10px;
	margin-bottom: 20px;
    }
    
    .aliexpress-commitment h4 {
	color: #ff4747;
	font-weight: bold;
    }
    
    .aliexpress-commitment ul {
	padding-left: 20px;
	margin-bottom: 10px;
    }
    
    .free-shipping {
	font-weight: bold;
    }
    
    .delivery-time {
	color: #666;
    }
    
    .security-privacy,
    .quantity-selector {
	margin-bottom: 20px;
    }
    
    .security-privacy p {
	color: #666;
	font-size: 0.8em;
    }
    
    .quantity-selector input {
	width: 60px;
	padding: 5px;
	margin-bottom: 5px;
    }
    
    .btn-block {
	display: block;
	width: 100%;
	margin-bottom: 10px;
    }
    
    .btn-danger {
	background-color: #ff4747;
	border-color: #ff4747;
    }
    
    .btn-outline-danger {
	color: #ff4747;
	border-color: #ff4747;
    }
    
    .btn-outline-danger:hover {
	background-color: #ff4747;
	color: white;
    }
    
    .action-buttons {
	display: flex;
	justify-content: space-between;
	margin-top: 10px;
    }
    
    .action-buttons .btn {
	flex: 1;
	margin: 0 5px;
    }