.btn-info {
    --bs-btn-color: #fff;
    --bs-btn-bg: #ff6600;
    --bs-btn-border-color: #ff6600;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #fe8d42;
    --bs-btn-hover-border-color: #fe8d42;
    --bs-btn-focus-shadow-rgb: 255,102,0;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #fb9e5f;
    --bs-btn-active-border-color: #fb9e5f;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: #ff6600;
    --bs-btn-disabled-border-color: #ff6600;
}

.btn-outline-primary {
    --bs-btn-color: #ff6600;
    --bs-btn-border-color: #ff6600;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: #ff6600;
    --bs-btn-hover-border-color: #ff6600;
    --bs-btn-focus-shadow-rgb: 13,110,253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: #fe8d42;
    --bs-btn-active-border-color: #fe8d42;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #ff6600;
    --bs-btn-disabled-bg: transparent;
    --bs-btn-disabled-border-color: #ff6600;
    --bs-gradient: none;
}