.rounded-container {
	border-radius: 25px;
	overflow: hidden; /* Ensures the rounded corners are maintained */
	width: 100%; /* Full width */
      }
      
      .rounded-left {
	border-top-left-radius: 25px;
	border-bottom-left-radius: 25px;
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
	border-right: 0;
	padding-left: 20px;
      }
      
      .rounded-right {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 25px;
	border-bottom-right-radius: 25px;
	border-left: 0;
      }

      .btn-rounded {
	border-radius: 50%;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 0;
      }

      .form-control::placeholder {
	color: white;
	opacity: 0.7; /* Adjust the opacity if needed */
      }