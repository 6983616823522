.modal-95w {
	width: 95vw;
	max-width: none!important;
      }
      
      .modal-95h {
	height: 95vh;
	max-height: 95vh!important;
      }
      
      .modal-extra-tall {
	height: calc(95vh - 56px); /* Subtracting approximate header height */
	max-height: none!important;
	overflow-y: hidden;
      }
      
      .modal-extra-tall iframe {
	height: 100%;
      }

      .products-column {
	max-width: 215px;
      }
      /**.products-column {
	max-height: 200px;
	overflow-y: auto;
	padding: 10px;
      }
      
      .product-item {
	padding: 2px 0;
      }
      
      .product-item:last-child {
	border-bottom: none;
      }
      
      .product-item ul {
	padding-left: 20px;
	margin-bottom: 0;
      }**/