

/* Custom tab navigation styles */
.custom-tab-nav .nav-item .nav-link {
	background-color: #a0a6ad; /* Default background color */
	border: 1px solid #dee2e6;
	border-bottom-color: transparent;
	margin-right: 5px;
	border-radius: 0.25rem 0.25rem 0 0; /* Rounded corners on top */
	transition: background-color 0.3s, color 0.3s;
      }
      
      .custom-tab-nav .nav-item .nav-link.active {
	background-color: #007bff; /* Active background color */
	border-color: #007bff; /* Active border color */
      }
      
      .custom-tab-nav .nav-item .nav-link:hover {
	background-color: #0056b3; /* Hover background color */
	border-color: #0056b3; /* Hover border color */
      }

      .black-tab-headers .nav-link {
	color: black;
      }
      
      .black-tab-headers .nav-link.active {
	color: black;
	font-weight: bold;
      }
      