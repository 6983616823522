.shopping-cart-sidebar {
	position: fixed;
	top: 0;
	right: -300px;
	width: 300px;
	height: 100%;
	background-color: #fff;
	box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
	transition: right 0.3s ease-in-out;
	z-index: 1001;
      }
      
      .shopping-cart-sidebar.open {
	right: 0;
      }
      
      .shopping-cart-sidebar.mobile {
	width: 80%;
	max-width: 300px;
	right: -80%;
      }
      
      .shopping-cart-sidebar.mobile.open {
	right: 0;
      }
      
      .cart-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 1rem;
	border-bottom: 1px solid #e0e0e0;
      }
      
      .cart-body {
	padding: 1rem;
	overflow-y: auto;
	height: calc(100% - 60px);
      }
      
      .overlay {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 1000;
      }
      
      @media (max-width: 767px) {
	body {
	  margin-right: 0 !important;
	}
      }

      .selected-option {
	display: block;
	font-size: 0.8rem;
	line-height: 1.2;
	color: #666;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
      }