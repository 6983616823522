body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
  }
  
  .App {
	text-align: center;
	width: 100%;
  }
  
  .App-header {
	background-color: #f8f9fa;
	padding: 0 0 10px 0;
  }
  
  .nav-link {
	color: #f8f9fa !important;
  }
  
  .navbar {
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #ff6600;
	padding: 0 20px;
	color: #e3e3e3;
  }
  
  .logo img {
	height: 40px;
	right: 20px;
	padding-top: 5px;
  }
  
  .menu a {
	margin: 0 15px;
	color: #fff;
	text-decoration: none;
  }
  
  .search-bar {
	display: flex;
	align-items: center;
  }
  
  .search-bar input {
	padding: 5px;
	border-radius: 4px;
	border: 1px solid #ccc;
  }
  
  .search-bar button {
	padding: 5px 10px;
	margin-left: 5px;
	background-color: #fff;
	border: 1px solid #ccc;
	border-radius: 4px;
	cursor: pointer;
  }
  
  .content {
	padding: 50px 0;
  }
  
  .content h2 {
	margin: 20px 0;
  }
  
  .content ul {
	list-style: none;
	padding: 0;
  }
  
  .content li {
	margin: 5px 0;
  }
  
  .location-container {
	display: flex;
	align-items: center;
	padding: 0 10px;
	padding-top: 5px;
	transition: background-color 0.3s ease, border 0.3s ease;
	border: 2px solid transparent;
  }
  
  .location-container:hover {
	background-color: #f0f0f0;
  }
  
  .location-container h4 {
	display: flex;
	align-items: center;
	margin-right: 5px;
	margin-left: 5px;
  }
  
  .navbar-nav {
	display: flex;
	justify-content: space-between;
	width: 100%;
  }
  
  .dropdown-menu {
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 15px;
  }
  
  .scroll-wrapper {
	position: relative;
	overflow: hidden;
  }
  
  .horizontal-scroll-container {
	overflow-x: auto;
	white-space: nowrap;
	position: relative;
	padding: 10px 5px;
	scrollbar-width: none;  /* Firefox */
	-ms-overflow-style: none;  /* Internet Explorer 10+ */
  }
  
  .horizontal-scroll-container::-webkit-scrollbar {
	display: none;  /* WebKit */
  }
  
  .horizontal-scroll-container .card {
	display: inline-block;
	margin-right: 1rem;
  }
  