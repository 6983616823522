      
      .logo {
	max-width: 100px;
      }
      
      .invoice-title {
	font-size: 24px;
	font-weight: bold;
	color: #333;
      }
      
      .table {
	margin-top: 20px;
      }
      
      .table th {
	background-color: #f0f0f0;
      }
      
      .wave-logo {
	max-width: 150px;
	margin-top: 20px;
      }
      
      /* Add more styles as needed to match the invoice design */

      .shipping-info.sticky-top {
            position: sticky;
            top: 20px; /* Adjust this value to set the distance from the top of the viewport */
            max-height: calc(100vh - 40px); /* Adjust this value to ensure it fits within the viewport */
            overflow-y: auto;
            background-color: #fff;
            padding: 15px;
            border: 1px solid #e0e0e0;
            border-radius: 5px;
            box-shadow: 0 2px 5px rgba(0,0,0,0.1);
          }
          
          /* Ensure the sticky element doesn't overlap with the footer */
          @media (min-height: 800px) {
            .shipping-info.sticky-top {
              max-height: calc(100vh - 200px);
            }
          }