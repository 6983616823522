.table-container {
	padding: 10px;
	padding-bottom: 30px;
	margin-bottom: 15px;
	background-color: #ffffff;
	border-radius: 12px;
	box-shadow: inset 1px 1px 10px rgba(0, 0, 0, 0.15); 
	position: relative;
	height: 400px; /* Adjust this value based on your needs */
	overflow: hidden;
      }

      .table-header-font {
	font-weight: 600 !important;
      }
      
      .recessed-table {
	border-radius: 8px;
	border: 1px solid #ffffff;
	overflow: auto;
	background-color: #ffffff;
	margin-top: 0;
	margin-bottom: 15px;
      }
      
      .recessed-table th,
      .recessed-table td {
	padding: 8px;
	text-align: left;
	border-bottom: 1px solid #ddd;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
      }
      
      .recessed-table thead th {
	font-weight: 600;
      }

      .table th {
	background-color: #ffffff !important;
      }
      
      .header-table {
	position: sticky;
	top: 0;
	z-index: 1;
	background-color: #ffffff; /* Adjust this to match your design */
      }

      .table-container table {
	width: 100%;
	border-collapse: separate;
	border-spacing: 0;
      }
      
      .table-container thead {
	position: sticky;
	top: 0;
	z-index: 1;
	background-color: #ffffff; /* Adjust this to match your design */
      }
      
      .table-body-container {
	height: calc(100% - 40px); /* Adjust 40px based on your header height */
	overflow-y: auto;
      }
      